<template>
  <div class="container kuan">
    <div class="main">
      <div class="zezhao" v-if="zezhao||weihu">
        <div class="zezhao_tip" v-if="zezhao">
          <section class="zz-header">温馨提示</section>
          <div class="zz_content">
            <section style="height:1px"></section>
            <img src="../../assets/closewarn.png" class="closewarn" alt="">
            <h2>{{currentGame.Gamename}}现已关盘</h2>
            <p>下期开盘时间为</p>
            <p class="green">{{kaipanTime}}</p>
          </div>
        </div>
        <div class="zezhao_tip" v-if="weihu">
          <section class="zz-header">温馨提示</section>
          <div class="zz_content">
            <section style="height:1px"></section>
            <img src="../../assets/closewarn.png" class="closewarn" alt="">
            <h2>{{currentGame.Gamename}}正在维护</h2>

          </div>
        </div>

      </div>
      <top-timer></top-timer>

      <div class="cate" style="margin-top:10px;position:relative">
        <span :class="{selected:wwed=='qianwu'}" @click="changeBSG('qianwu')">口XXX口</span>
        <span :class="{selected:wwed=='baiwu'}" @click="changeBSG('baiwu')">X口XX口</span>
        <span :class="{selected:wwed=='shiwu'}" @click="changeBSG('shiwu')">XX口X口</span>
        <span :class="{selected:wwed=='gewu'}" @click="changeBSG('gewu')">XXX口口</span>

        <span class="modebtn" @click="changemode">模式{{modeText}}</span>
      </div>

      <div class="player listmain type-f3d f3d game-f3d game-f3d-2zh">

        <div class="data" v-if="mode==1">
          <h3>{{rowsData[0].title}}</h3>
          <ul class="custom_ul w98per">
            <li :class="ele.selected?'selected':''" v-for="ele in rowsData[0].arr" @click="setCurrentSelected(ele)" :key="ele.ResultID">
              <ol class="td_name">{{ele.label}}</ol>
              <ol class="td_rate">{{ele.Odds}}</ol>

            </li>
            <li class="w2"><i @click="setRowSel([0,10,20,30,40,50,60,70,80,90])" class="el-icon-caret-left"></i></li>
            <li class="w2"><i @click="setRowSel([1,11,21,31,41,51,61,71,81,91])" class="el-icon-caret-left"></i></li>
            <li class="w2"><i @click="setRowSel([2,12,22,32,42,52,62,72,82,92])" class="el-icon-caret-left"></i></li>
            <li class="w2"><i @click="setRowSel([3,13,23,33,43,53,63,73,83,93])" class="el-icon-caret-left"></i></li>
            <li class="w2"><i @click="setRowSel([4,14,24,34,44,54,64,74,84,94])" class="el-icon-caret-left"></i></li>
            <li class="w2"><i @click="setRowSel([5,15,25,35,45,55,65,75,85,95])" class="el-icon-caret-left"></i></li>
            <li class="w2"><i @click="setRowSel([6,16,26,36,46,56,66,76,86,96])" class="el-icon-caret-left"></i></li>
            <li class="w2"><i @click="setRowSel([7,17,27,37,47,57,67,77,87,97])" class="el-icon-caret-left"></i></li>
            <li class="w2"><i @click="setRowSel([8,18,28,38,48,58,68,78,88,98])" class="el-icon-caret-left"></i></li>
            <li class="w2"><i @click="setRowSel([9,19,29,39,49,59,69,79,89,99])" class="el-icon-caret-left"></i></li>
          </ul>
          <ul class="custom_ul w98per kuaixuan">
            <li class="liqx"> <i @click="setColSel(0,9)" class="el-icon-caret-top"></i></li>
            <li class="liqx"> <i @click="setColSel(10,19)" class="el-icon-caret-top"></i></li>
            <li class="liqx"> <i @click="setColSel(20,29)" class="el-icon-caret-top"></i></li>
            <li class="liqx"> <i @click="setColSel(30,39)" class="el-icon-caret-top"></i></li>
            <li class="liqx"> <i @click="setColSel(40,49)" class="el-icon-caret-top"></i></li>
            <li class="liqx"> <i @click="setColSel(50,59)" class="el-icon-caret-top"></i></li>
            <li class="liqx"> <i @click="setColSel(60,69)" class="el-icon-caret-top"></i></li>
            <li class="liqx"> <i @click="setColSel(70,79)" class="el-icon-caret-top"></i></li>
            <li class="liqx"> <i @click="setColSel(80,89)" class="el-icon-caret-top"></i></li>
            <li class="liqx"> <i @click="setColSel(90,99)" class="el-icon-caret-top"></i></li>
            <li class="w2"></li>
          </ul>
        </div>
        <div class="kuaixuan_wrap" v-if="mode==1">
          <section class="kx_item san">
            <span class="kx_left">定位置</span>
            <section class="kx_right">
              <div class="kx_row shang">
                <em>{{dingwei.title1}}</em>
                <span v-for="kn in kxlist" :class="{selected:kn.selected}" @click="setwz(kn)" :key="kn.label">{{kn.label}}</span>
              </div>
              <div class="kx_row">
                <em>{{dingwei.title2}}</em>
                <span v-for="kn in kxlist2" :class="{selected:kn.selected}" @click="setwz(kn)" :key="kn.label+11">{{kn.label}}</span>
              </div>
            </section>

          </section>
          <section class="kx_item er">
            <span class="kx_left">合分</span>
            <section class="kx_right">
              <div class="kx_row da">
                <span v-for="kn in kxlist1" :class="{selected:kn.selected}" @click="setwz(kn)" :key="kn.label+21">{{kn.label}}</span>
              </div>

            </section>
          </section>
        </div>
        <div class="data" v-if="mode==2">
          <h3>{{rowsData[0].title}}</h3>
          <ul class="custom_ul_h">
            <li class="h50" :class="ele.selected?'selected':''" v-for="ele in rowsData[0].arr" @click="setCurrentSelected(ele)" :key="ele.ResultID">
              <ol class="td_name">
                <span class="red">{{ele.Odds}}</span>
                {{ele.label}}

              </ol>

              <ol class="td_cash">
                <input type="text" @click.stop="showOrHideCheck($event, ele)" v-model="ele.money" />
                <div class="quick-check" v-if="ele.b">
                  <ul>
                    <li style="width:40px" v-for="qs in quickSelectedData" :key="qs.num + '元'" @click.stop="setCurrentName(ele, qs.num)">
                      下注{{ qs.num }}元
                    </li>

                    <li @click="closeCheck(ele)">关闭</li>
                  </ul>
                </div>
              </ol>
            </li>
          </ul>
        </div>
      </div>
      <yushe />
    </div>

  </div>
</template>

<script>
import topTimer from "../mainHeader.vue";
import yushe from "../yushe.vue";
import { mapState } from "vuex";
import mixins from "../mixins/mainMixins";
export default {
  name: "wuweierding",
  props: [""],
  mixins: [mixins],
  components: {
    yushe,
    topTimer,
  },
  data() {
    return {
      groupnames:'wwedqw,wwedbw,wwedsw,wwedgw',
      mode: 1,
      modeText: 2,
      kxlist: [
        { label: "0", selected: false },
        { label: "1", selected: false },
        { label: "2", selected: false },
        { label: "3", selected: false },
        { label: "4", selected: false },
        { label: "5", selected: false },
        { label: "6", selected: false },
        { label: "7", selected: false },
        { label: "8", selected: false },
        { label: "9", selected: false },
        { label: "单", selected: false },
        { label: "双", selected: false },
        { label: "大", selected: false },
        { label: "小", selected: false },
      ],
      kxlist2: [
        { label: "0", selected: false },
        { label: "1", selected: false },
        { label: "2", selected: false },
        { label: "3", selected: false },
        { label: "4", selected: false },
        { label: "5", selected: false },
        { label: "6", selected: false },
        { label: "7", selected: false },
        { label: "8", selected: false },
        { label: "9", selected: false },
        { label: "单", selected: false },
        { label: "双", selected: false },
        { label: "大", selected: false },
        { label: "小", selected: false },
      ],
      kxlist1: [
        { label: "0", selected: false },
        { label: "1", selected: false },
        { label: "2", selected: false },
        { label: "3", selected: false },
        { label: "4", selected: false },
       
        { label: "5", selected: false },
        { label: "6", selected: false },
        { label: "7", selected: false },
        { label: "8", selected: false },
        { label: "9", selected: false },
        { label: "单", selected: false },
        { label: "双", selected: false },
      ],
      // 长龙 tab
      changelongActive: "1",
      baishige: "1",
      // 连续开  连续未开 tab
      kaiActive: "1",
      rowsData: [],
      qianwu: { title: "仟五-口XXX口", arr: [] },
      baiwu: { title: "佰五-X口XX口", arr: [] },
      shiwu: { title: "拾五-XX口X口", arr: [] },
      gewu: { title: "个五-XXX口口", arr: [] },
      dingwei: { title1: "", title2: "" },
      oddData1: [],
    };
  },
  watch: {
    pan(val) {},
    rowsData: {
      deep: true,
      handler(val) {
        this.$nextTick(() => {
          let arr = [];
          val.forEach((item) => {
            item.arr.forEach((ele) => {
              if (ele.money) {
                let obj = {
                  label: ele.label,
                  Odds: ele.Odds,
                  title: item.title,
                  id: ele.ResultID,
                  money: Number(ele.money),
                };
                arr.push(obj);
              }
            });
          });

          // console.log(arr, "arrrr");
          if (arr.length > 0) {
            this.$store.commit("setXZList", arr);
          } else {
            this.$store.commit("setXZList", []);
          }
        });
      },
    },
    // j监听数据
    oddData1: {
      immediate: true,
      deep: true,
      handler(resAArr) {
        let labelArr1 = [],
          labelArr2 = [],
          labelArr3 = [],
          labelArr4 = [];

        for (let index = 0; index < 100; index++) {
          let label1 = this.checkqianwu(index); //仟五
          let label2 = this.checkbaiwu(index); //佰五
          let label3 = this.checkshiwu(index); //拾五
          let label4 = this.checkgewu(index); //个五

          labelArr1.push(label1);
          labelArr2.push(label2);
          labelArr3.push(label3);
          labelArr4.push(label4);
        }
        //  仟五
        let arr1 = this.filterData(resAArr, 6208, 6307, labelArr1);
        this.qianwu.arr = arr1;
        // 百五
        let arr2 = this.filterData(resAArr, 6308, 6407, labelArr2);
        this.baiwu.arr = arr2;
        // 十五
        let arr3 = this.filterData(resAArr, 6408, 6507, labelArr3);
        this.shiwu.arr = arr3;
        // 个五
        let arr4 = this.filterData(resAArr, 6508, 6607, labelArr4);
        this.gewu.arr = arr4;

        this.getBSG();
        this.$forceUpdate();
      },
    },
  },

  created() {
  
    
  },

  methods: {
    changemode() {
      if (this.mode == 1) {
        this.mode = 2;
        this.modeText = 1;
      } else {
        this.mode = 1;
        this.modeText = 2;
      }
      this.kxlist.forEach((item) => {
        item.selected = false;
      });
      this.kxlist2.forEach((item) => {
        item.selected = false;
      });
      this.kxlist1.forEach((item) => {
        item.selected = false;
      });
      this.rowsData.forEach((item) => {
        item.arr.forEach((ele) => {
          ele.selected = false;
          ele.money = "";
        });
      });
    },
    setColSel(index1, index2) {
      this.rowsData.forEach((item) => {
        item.arr.forEach((ele, index) => {
          if (index >= index1 && index <= index2) {
            ele.selected = !ele.selected;
            ele.money = ele.selected ? this.amount : "";
          }
        });
      });
    },
    setRowSel(indexArr) {
      indexArr.forEach((n) => {
        this.rowsData.forEach((item) => {
          item.arr[n].selected = !item.arr[n].selected;
          item.arr[n].money = item.arr[n].selected ? this.amount : "";
        });
      });
    },
    setwz(kn) {
      kn.selected = !kn.selected;
      this.jtdwz1();
    },
    // 监听定位置组合
    jtdwz1() {
      let b1 = this.kxlist.some((item) => item.selected);
      let b2 = this.kxlist2.some((item) => item.selected);
      let b3 = this.kxlist1.some((item) => item.selected);
      if ((b1 && b2) || b3) {
        let numArr1 = [];
        this.kxlist.forEach((item) => {
          if (item.selected) {
            numArr1.push(item.label);
          }
        });
        let numArr2 = [];

        this.kxlist2.forEach((item) => {
          if (item.selected) {
            numArr2.push(item.label);
          }
        });
        let numArr3 = [];
        this.kxlist1.forEach((item) => {
          if (item.selected) {
            numArr3.push(item.label);
          }
        });
        let hunheArr = this.multiplyData([numArr1, numArr2]);
        if (hunheArr.length > 0 || numArr3.length > 0) {
          if (this.wwed == "qianwu") {
            this.setDataSelectedNum1(hunheArr, numArr3, 0, 4);
          } else if (this.wwed == "baiwu") {
            this.setDataSelectedNum1(hunheArr, numArr3, 1, 4);
          } else if (this.wwed == "shiwu") {
            this.setDataSelectedNum1(hunheArr, numArr3, 2, 4);
          } else if (this.wwed == "gewu") {
            this.setDataSelectedNum1(hunheArr, numArr3, 3, 4);
          }
        }
      } else {
        this.rowsData.forEach((item) => {
          item.arr.forEach((ele) => {
            ele.selected = false;
            ele.money = "";
          });
        });
      }
    },

    setDataSelectedNum1(hunheArr, numArr3, index1, index2) {
      this.rowsData.forEach((item) => {
        item.arr.forEach((ele) => {
          let label1 = ele.label[index1];
          let label2 = ele.label[index2];
          // 定位置
          let b = this.xhHunHeArr(hunheArr, label1, label2);
          // 合分
          let henum = Number(label1) + Number(label2);

          let d = this.xhHefenArr(numArr3, henum);

          if (b || d) {
            ele.selected = true;
            ele.money = this.amount;
          } else {
            ele.selected = false;
            ele.money = "";
          }
        });
      });
    },
    xhHefenArr(arr, henum) {
      let b = false;
      let b1 = false;
      b = arr.some((itemText) => {
        if (this.isNumber(itemText)) {
          if (henum < 10) {
            b1 = itemText == henum ? true : false;
          } else {
            let hestr = henum + "";
            b1 = itemText == hestr[1] ? true : false;
          }
        } else {
          b1 = this.pdhanz(itemText, henum);
        }
        return b1;
      });
      return b;
    },
    // 遍历混合 arr
    xhHunHeArr(arr, label1, label2) {
      let b = false,
        b1 = false,
        b2 = false;
      b = arr.some((itemarr) => {
        if (this.isNumber(itemarr[0])) {
          b1 = label1 == itemarr[0] ? true : false;
        } else {
          b1 = this.pdhanz(itemarr[0], label1);
        }
        if (this.isNumber(itemarr[1])) {
          b2 = label2 == itemarr[1] ? true : false;
        } else {
          b2 = this.pdhanz(itemarr[1], label2);
        }

        return b1 && b2;
      });

      return b;
    },
    pdhanz(str, num) {
      let b = false;
      if (str == "单") {
        b = num % 2 != 0 ? true : false;
      } else if (str == "双") {
        b = num % 2 == 0 ? true : false;
      } else if (str == "大") {
        b = num >= 5 ? true : false;
      } else if (str == "小") {
        b = num < 5 ? true : false;
      }
      return b;
    },
    changeBSG(num) {
      this.$store.commit("SETWWEDACTIVE", num);
      this.setDW();
      this.rowsData = [JSON.parse(JSON.stringify(this[num]))];
      this.kxlist.forEach((item) => {
        item.selected = false;
      });
      this.kxlist2.forEach((item) => {
        item.selected = false;
      });
      this.kxlist1.forEach((item) => {
        item.selected = false;
      });
      if (this.ISOPEN) {
        this.rowsData[0].arr.forEach((item) => {
          item.Odds = item.recordOdds;
        });
      }else{
        this.rowsData[0].arr.forEach((item) => {
          item.Odds = '--';
        });
      }
    },
    getBSG() {
      this.setDW();
      this.rowsData = [JSON.parse(JSON.stringify(this[this.wwed]))];
      if (this.ISOPEN) {
        this.rowsData[0].arr.forEach((item) => {
          item.Odds = item.recordOdds;
        });
      }else{
        this.rowsData[0].arr.forEach((item) => {
          item.Odds = '--';
        });
      }
    },
    setDW() {
      switch (this.wwed) {
        case "qianwu":
          this.dingwei = { title1: "仟", title2: "五" };
          break;
        case "baiwu":
          this.dingwei = { title1: "佰", title2: "五" };
          break;
        case "shiwu":
          this.dingwei = { title1: "拾", title2: "五" };
          break;
        case "gewu":
          this.dingwei = { title1: "个", title2: "五" };
          break;

        default:
          break;
      }
    },
    checkNum(num) {
      let newcount = num;
      if (num < 10) {
        newcount = "0" + num;
      }

      return newcount;
    },
    // 千五
    checkqianwu(num) {
      let newcount = num;
      if (num < 10) {
        newcount = "0" + num;
      }
      let str = newcount + "";

      let label = str[0] + "XXX" + str[1];
      return label;
    },
    // 佰五
    checkbaiwu(num) {
      let newcount = num;
      if (num < 10) {
        newcount = "0" + num;
      }
      let str = newcount + "";

      let label = "X" + str[0] + "XX" + str[1];
      return label;
    },
    // 拾五
    checkshiwu(num) {
      let newcount = num;
      if (num < 10) {
        newcount = "0" + num;
      }
      let str = newcount + "";

      let label = "XX" + str[0] + "X" + str[1];
      return label;
    },
    // 个五
    checkgewu(num) {
      let newcount = num;
      if (num < 10) {
        newcount = "0" + num;
      }
      let str = newcount + "";

      let label = "XXX" + str[0] + str[1];
      return label;
    },

    multiplyData(newArrData) {
      let len = newArrData.length;
      // 当数组长度大于等于2时
      if (len >= 2) {
        // 第一个数组的长度
        let len1 = newArrData[0].length;
        // 第二个数组的长度
        let len2 = newArrData[1].length;
        //  申明一个新数组,做数据暂存
        let items = new Array();
        // 申明新数组的索引
        let index = 0;
        // 2层嵌套循环,将组合放到新数组中
        for (var i = 0; i < len1; i++) {
          for (var j = 0; j < len2; j++) {
            if (items.length < index + 1) {
              items.push([]);
            }
            if (newArrData[0][i] instanceof Array) {
              items[index] = items[index].concat(
                newArrData[0][i].concat(newArrData[1][j])
              );
            } else {
              items[index] = items[index].concat([
                newArrData[0][i],
                newArrData[1][j],
              ]);
            }
            index++;
          }
        }
        // 将新组合的数组并到原数组中
        let newArr = new Array(len - 1);
        for (let i = 2; i < newArrData.length; i++) {
          newArr[i - 1] = newArrData[i];
        }
        newArr[0] = items;
        // 执行回调
        return this.multiplyData(newArr);
      } else {
        // console.log(newArrData[0]);
        return newArrData[0];
      }
    },
  },
};
</script>
<style  scoped>
.selmode {
}
.kx_row.da {
  margin-left: 20px;
  width: 70%;
  height: 100%;
  flex-wrap: wrap;
}

.skin-blue .kx_row span.selected {
  color: #fff;
  background: #2161b3;
}
.skin-brown .kx_row span.selected {
  color: #fff;
  background: #a18361;
}
.skin-red .kx_row span.selected {
  color: #fff;
  background: #c2455c;
}
.kx_row span {
  margin-left: 4px;
  display: inline-block;
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  background: #f7f7f7;
  font-size: 15px;
  border: 1px solid rgb(187, 185, 185);
  font-weight: bold;
  border-radius: 5px;
}

.kx_row.shang {
  border-bottom: 1px solid lightgray;
}
.kx_row {
  display: flex;
  align-items: center;
  height: 50%;
}
.kx_right {
  flex: 1;
  height: 100%;
}

.kx_item.san {
  width: 658px;
  border-right: 1px solid lightgray;
}
.kx_item.er {
  flex: 1;
}
.kx_left {
  width: 110px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  border-right: 1px solid lightgray;
}
.skin-blue .kx_left {
  background: #edf4fe;
}
.skin-brown .kx_left {
  background: #f2ede2;
}
.skin-red .kx_left {
  background: #fff5f5;
}
.kx_item {
  height: 100%;
  display: flex;
}
.kuaixuan_wrap {
  display: flex;
  margin-top: 2px;
  width: 100%;
  height: 110px;
  border: 1px solid lightgray;
}
li.liqx i,
li.w2 i {
  font-size: 20px;
  color: #666;
}
li.w2 {
  display: flex;
  align-items: center;
  justify-content: center;
}
.liqx {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.listmain > .data > ul.custom_ul.kuaixuan {
  height: 26px;
}
.listmain > .data > ul.custom_ul {
  /* width: 98%; */
  height: 260px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  flex-flow: none;
}
.listmain .data {
  /* width: 90%; */
}
.listmain .data ul li {
  width: 10%;
  height: 26px;
}
.listmain > .data > ul.custom_ul_h {
  height: 400px;
}
.listmain .data ul li.h50 .td_name {
  border-right: none;
  min-width: 50px !important;
  flex-direction: column;
}
.listmain .data ul li.h50 {
  height: 40px;
}
.listmain .data ul li.w2 {
  width: 2%;
}
</style>